import {ApolloClient, InMemoryCache} from "@apollo/client";
import Auth from "@cadent/ulp-auth";
import CryptoJS from "crypto-js";
import buildHasuraProvider from "ra-data-hasura";
import React, {useEffect, useState} from "react";
import {Admin, Authenticated, Resource, UPDATE_MANY} from "react-admin";
import {Redirect, Route, useLocation} from "react-router-dom";
import "./App.css";
import {authProvider, getRole, getRolesFromAuth} from "./authProvider";
import {LayoutComponent} from "./components/layout/";
import {customBuildVariables} from "./dataProvider/buildVariables";
import {customBuildFields} from "./dataProvider/customBuildFields";
import {customGetResponseParser} from "./dataProvider/customGetResponseParser";
import {ArList, ArShow} from "./pages/ar";
import {BrsCreate, BrsList, BrsShow, TrafficInstructionEdit} from "./pages/brs";
import {DestinationCreate, DestinationEdit, DestinationList,} from "./pages/destinations";
import {FormatCreate, FormatEdit, FormatList} from "./pages/formats";
import {JobList, JobShow} from "./pages/jobs";
import {EmailTemplateList, EmailTemplateCreate, EmailTemplateEdit} from "./pages/templates"
import {LocationCreate, LocationEdit, LocationList} from "./pages/locations";
import {Login} from "./pages/login";
import {RecipientCreate, RecipientEdit, RecipientList,} from "./pages/recipients";
import {TranscoderCreate, TranscoderEdit, TranscoderList} from "./pages/transcoders";
import {removeUntilFirstSlash} from "./helpers";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from '@material-ui/core/styles';
import {PodCreate} from "./pages/ar/PodCreate";
import {BrandCreate, BrandEdit, BrandList} from "./pages/brand";
import {InventorySourceCreate, InventorySourceEdit, InventorySourceList} from "./pages/inventorySource";

let uri = window.REACT_APP_DH_GRAPHQL_URI;


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function authenticate(setIsAuth) {
  return new Promise((resolve) => {
    Auth.init({
      appId: process.env.REACT_APP_APPLICATION_ID,
      env: window.NODE_ENV,
      isInternalApp: false,
      initialUserPath: window.location.origin,
      routerBaseName: '/',
      onAuthenticated: () => {
        resolve();
      },
      onStatusUpdate: (status) => {
        // console.log(JSON.stringify(status, null, 2));
      },
    });
  });
}

// Usage


const HasuraApp = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const [waiting, setWaiting] = useState(false)
  const [open, setOpen] = useState(false)
  const classes = useStyles();
  let location = useLocation();

  let KEY =
      process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_TOKEN_LOCAL
          : window.NODE_ENV === "production"
              ? process.env.REACT_APP_TOKEN_PRODUCTION
              : window.NODE_ENV === "development"
                  ? process.env.REACT_APP_TOKEN_DEV
                  : window.NODE_ENV === "stage"
                      ? process.env.REACT_APP_TOKEN_STAGING
                      : null;

  let roles =
      window.CADENT_SSO_V2 !== "true" && localStorage.getItem("app") ?
          JSON.parse(
              CryptoJS.AES.decrypt(
                  localStorage.getItem("app"),
                  process.env.REACT_APP_APPLICATION_ID
              ).toString(CryptoJS.enc.Utf8)
          ).roles
          : null;

  if (window.CADENT_SSO_V2 === "true" && !Auth.isAuthenticated() && !waiting) {
    setWaiting(true)
    authenticate(setWaiting)
        .then(() => {
          setWaiting(false)
          roles = Auth.getUser().Roles
        })
        .catch((error) => {
          console.error(error);
        });
  } else if (window.CADENT_SSO_V2 === "true" && Auth.isAuthenticated() && !waiting) {
    roles = Auth.getUser().Roles
  }


  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${KEY}`,
    };
    if (roles) {
      headers["x-hasura-roles"] = getRole(roles);
      setOpen(true)
    } else {
      setOpen(false)
    }

    const buildDataProvider = async () => {
      const client = new ApolloClient({
        uri,
        reconnect: true,
        cache: new InMemoryCache(),
        headers,
        fetchOptions: {
          mode: "no-cors",
        },
      });
      const dataProvider = await buildHasuraProvider(
          {
            client,
            introspection: {
              operationNames: {
                [UPDATE_MANY]: (resource) => `update_${resource.name}_many`,
              },
            }
          },
          {buildFields: customBuildFields},
          customBuildVariables,
          customGetResponseParser
      );
      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (
      roles && getRole(roles) === "ar-user" && ["#/job","#/brs", '#/transcoder', '#/transcoder', '#/transcoder', '#/format', '#/location', '#/destination', '#/recipient'].includes(removeUntilFirstSlash(location.hash))
  ) {
    return <Redirect to="#/ar"/>;
  } else if (
      roles && getRole(roles) === "brs-user" && ["#/job", '#/ar', '#/transcoder', '#/transcoder', '#/transcoder', '#/format', '#/location', '#/destination'].includes(removeUntilFirstSlash(location.hash))
  ) {
    return <Redirect to="#/brs"/>;
  }

  if (!dataProvider) return <p>Loading...</p>;
  return (
      dataProvider && (
          <><Admin
              loginPage={Login}
              authProvider={authProvider}
              layout={LayoutComponent}
              customRoutes={[
                <Route
                    path="/ar"
                    render={() => (
                        <Authenticated>
                          <ArList/>
                        </Authenticated>
                    )}
                />,
                <Route
                    path="/brs/create"
                    render={() => (
                        <Authenticated>
                          <BrsCreate/>
                        </Authenticated>
                    )}
                />,
                <Route
                    path="/pods/create"
                    render={() => (
                        <Authenticated>
                          <PodCreate/>
                        </Authenticated>
                    )}
                />,
                <Route
                    path="/brs/:id"
                    render={(props) => (
                        <Authenticated>
                          <BrsShow {...props} />
                        </Authenticated>
                    )}
                />,
                <Route
                    path="/brs"
                    render={() => (
                        <Authenticated>
                          <BrsList/>
                        </Authenticated>
                    )}
                />,
                <Route
                    path="/traffic-instructions/:id"
                    render={(props) => (
                        <Authenticated>
                          <TrafficInstructionEdit {...props}/>
                        </Authenticated>
                    )}
                />,
              ]}
              dataProvider={dataProvider}
          >
            <Resource name="job" list={JobList} show={JobShow}/>
            <Resource
                name="format"
                list={FormatList}
                create={FormatCreate}
                edit={FormatEdit}
            />
            <Resource
                name="destination"
                list={DestinationList}
                create={DestinationCreate}
                edit={DestinationEdit}
            />
            <Resource
                name="location"
                list={LocationList}
                create={LocationCreate}
                edit={LocationEdit}
            />
            <Resource
                name="transcoder"
                list={TranscoderList}
                create={TranscoderCreate}
                edit={TranscoderEdit}
            />
            <Resource name="ftplocation"/>
            <Resource name="pathmapping"/>
            <Resource name="auditlog"/>
            <Resource name="brsjobdestination"/>
            <Resource name="brsnotification"/>
            <Resource name="emailtemplate" list={EmailTemplateList} create={EmailTemplateCreate} edit={EmailTemplateEdit}
            />
            <Resource
                name="recipient"
                list={RecipientList}
                create={RecipientCreate}
                edit={RecipientEdit}
            />
            <Resource name="destinationrecipient"/>
            <Resource name="reel" show={ArShow}/>
            <Resource name="pod" show={ArShow}/>
            <Resource name="brsjob"/>
            <Resource
                name="brand"
                list={BrandList}
                create={BrandCreate}
                edit={BrandEdit}
            />
            <Resource
                name="inventorysource"
                list={InventorySourceList}
                create={InventorySourceCreate}
                edit={InventorySourceEdit}
            />
            <Resource name="broadcastrequest"/>
          </Admin>
            {window.CADENT_SSO_V2 === "true" && <Backdrop className={classes.backdrop} open={open}>
              <CircularProgress color="inherit"/>
            </Backdrop>}

          </>
      )
  );
};

export default HasuraApp;
